<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<template>
  <Header title="videos" />
  <Container>
    <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
      <div v-for="v in videos" :key="v.title" class="">
        <div class="rounded-lg object-cover relative overflow-hidden aspect-w-3 aspect-h-2 group cursor-pointer" v-on:click="
                                open = true;
                                youtubeId = v.id;
                              ">
          <img :src="'https://img.youtube.com/vi/' + v.id + '/hqdefault.jpg'" class="object-cover	transform" alt="holding a torch" />
          <div class="absolute inset-0 bg-black bg-opacity-50 transition group-hover:bg-opacity-40 grid place-items-center">
            <div>
              <img src="@/assets/play.svg" alt="play button" class="w-20 h-20" />
            </div>
          </div>
        </div>
        <h3 class="text-2xl mt-4">{{ v.title }} | {{ v.topic }}</h3>
      </div>
    </div>
    <!-- <nav
      class="py-3 flex items-center justify-between border-t border-accent-darkest mt-6"
      aria-label="Pagination"
    >
      <div class="hidden sm:block">
        <p class="text-sm text-accent-darkest">
          Showing
          {{ " " }}
          <span class="font-medium">1</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">10</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">20</span>
          {{ " " }}
          results
        </p>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end">
        <button class="btn btn-lg"> Previous </button>
        <button class="ml-3 btn btn-lg"> Next </button>
      </div>
    </nav> -->
  </Container>

  <SimpleBrandCTA />

<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" @click="open = false">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start mt-8">
              <div class="text-center sm:mt-0 sm:text-left  w-full">
                <div class="aspect-w-16 aspect-h-9 w-full">
                  <iframe class="w-full h-full" :src="'https://www.youtube.com/embed/' + youtubeId + '?autoplay=true'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { ref } from 'vue'
import Container from "../../components/layouts/Container.vue";
import SimpleBrandCTA from "../../components/layouts/cta/SimpleBrandCTA.vue";
import Header from "@/components/base/Header";
import { Dialog, DialogOverlay,  TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

const videos = [
  {
    title: "Ted Michaels",
    topic: "How The Long Road Back Came About",
    id: "p-LxuTq8hL4",
  },
  {
    title: "Ted Michaels",
    topic: "The Long Road Back Documentary",
    id: "mwox7BX4k9g",
  },
  {
    title: "CHML Chicago",
    topic: "Interview with Ted Michaels",
    id: "DJDKey-BxfQ",
  },
  {
    title: "Ted Michaels",
    topic: "Pan Am Torch Relay 2015",
    id: "p2NnfV96V3g",
  },
];

export default {
  components: {
    Header,
    SimpleBrandCTA,
    Container,  
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  setup() {
    const open = ref(false)
    const youtubeId = "";
    return {
      open,
      youtubeId
    }
  },
  data() {
    return {
      videos,
    };
  },
   methods: {
      openVideo(id){
        alert(id);
      }
    },
};
</script>
